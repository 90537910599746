.component {
  font-size: theme('fontSize.16');
  padding-top: theme('spacing.4x');
}

.gray {
  background-color: var(--color-gray);
}

.link {
  border-bottom: theme('spacing.1xxs') solid theme('colors.primary');
  color: theme('colors.primary');
  font-family: theme('fontFamily.beatrice-regular');
  height: 100%;
  padding: theme('spacing.1x') theme('spacing.2x');
  text-align: center;
  text-decoration: unset;
  transition: border 200ms ease-out;
  white-space: nowrap;
  width: 170px;
}

.link:hover {
  color: theme('colors.white');
}

.active {
  border-color: theme('colors.white');
  color: theme('colors.white');
}

.logo {
  margin: 0 theme('spacing.6x') 0 theme('spacing.2x');
  width: 140px;
}

.survey {
  background-color: theme('colors.primary');
  color: theme('colors.black');
  padding: theme('spacing.1x') theme('spacing.3x');
  white-space: nowrap;
  width: unset;
}

.component:global(.bg-pine) .active {
  border-color: theme('colors.forest');
  color: theme('colors.forest');
}

.component:global(.bg-pine) .link {
  border-bottom-color: theme('colors.forest');
  color: theme('colors.forest');
}

.component:global(.bg-pine) .link:hover {
  color: theme('colors.forest');
}

.component:global(.bg-white) .active {
  border-color: theme('colors.forest');
  color: theme('colors.forest');
}

.component:global(.bg-white) .link:hover {
  color: theme('colors.forest');
}

@screen lg {
  .component {
    font-size: theme('fontSize.18');
  }

  .link {
    width: 212px;
  }

  .survey {
    padding: theme('spacing.1x') theme('spacing.6x');
    width: unset;
  }
}
