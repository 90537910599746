* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: geometricprecision;
}

body {
  color: theme('colors.forest');
  font-family: theme('fontFamily.central-no1-light');
  margin: 0;
  min-width: 320px;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: theme('fontFamily.beatrice-light');
  font-weight: unset;
  line-height: 1.3;
}

p {
  font-size: theme('fontSize.16');
  margin: 0 0 1em;
}

p:last-of-type {
  margin-bottom: 0;
}

@screen md {
  p {
    font-size: theme('fontSize.20');
  }
}

@screen lg {
  p {
    font-size: theme('fontSize.24');
  }
}

main {
  /* min-height: calc(100vh - var(--header-height)); */
  width: 100%;
}

button {
  background-color: transparent;
  border: unset;
  cursor: pointer;
}

label {
  cursor: pointer;
  display: block;
  margin-bottom: theme('spacing.1x');
  margin-left: theme('spacing.1x');
}

input,
textarea {
  border: 1px solid theme('colors.forest');
  border-radius: theme('spacing.1xs');
  display: block;
  margin-bottom: theme('spacing.2x');
  padding: theme('spacing.2x');
  transition: border 200ms ease-out, outline 200ms ease-out;
  width: 100%;
}

input:focus,
input:focus-visible {
  outline-color: theme('colors.turmeric');
}

input:not(:empty):invalid {
  border-color: theme('colors.rust');
  outline-color: theme('colors.rust');
}

hr {
  border: unset;
  border-bottom: 1px solid theme('colors.white');
  border-top: 1px solid theme('colors.gray.2');
  margin: theme('spacing.6x') 0;
}

@keyframes loader {
  0% {
    border-left-color: theme('colors.turmeric');
    transform: rotate(0deg);
  }

  5% {
    border-left-color: theme('colors.turmeric');
  }

  45%,
  75% {
    border-left-color: theme('colors.pine');
  }

  95% {
    border-left-color: theme('colors.turmeric');
  }

  100% {
    border-left-color: theme('colors.turmeric');

    /* stylelint-disable unit-allowed-list */
    transform: rotate(2turn);
  }
}

/* Work in progress (an idea) */
@keyframes spin {
  0% {
    color: theme('colors.turmeric');
    transform: rotate(0deg);
  }

  25% {
    color: theme('colors.rust');
  }

  50% {
    color: theme('colors.pine');
  }

  75% {
    color: theme('colors.turmeric');
  }

  100% {
    color: theme('colors.rust');
    transform: rotate(2turn);
  }
}
