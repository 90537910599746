.component {
  background-color: theme('colors.white');
  border-bottom: 1px solid theme('colors.gray.2');
  height: var(--header-height);
  overflow: visible;
  position: sticky;
  top: 0;
  transition: background 140ms ease-out;
  z-index: 9;
}

.component.transparent {
  background-color: transparent;
  border-bottom-color: transparent;
}

.component a {
  background-color: transparent;
}

.component:global(.active) {
  background-color: theme('colors.white');
}

.component button {
  margin-right: theme('spacing.1x');
}

.link {
  color: theme('colors.gray.8');
  font-size: theme('fontSize.14');
  margin-right: theme('spacing.1x');
  padding: theme('spacing.2x');
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
}

.link:after,
.active:after {
  background-color: theme('colors.turmeric');
  bottom: 14px;
  content: '';
  height: 2px;
  left: theme('spacing.2x');
  opacity: 0;
  position: absolute;
  right: 100%;

  /* prettier-ignore */
  transition: background 140ms ease-out, opacity 140ms ease-out, right 240ms ease-in-out;
}

.link:hover {
  color: theme('colors.gray.8');
  font-size: theme('fontSize.14');
  padding: theme('spacing.2x');
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
}

.link:hover:after {
  background-color: theme('colors.turmeric');
  opacity: 1;
  right: theme('spacing.2x');
}

.active {
  cursor: default;
}

.active:after,
.active:hover:after {
  background-color: theme('colors.gray.8');
  opacity: 1;
  right: theme('spacing.2x');
}

.logo {
  max-width: 120px;
}

.routine {
  display: none;
}

@screen md {
  .component {
    height: var(--header-height);
  }

  .routine {
    display: block;
    margin-right: theme('spacing.2x');
  }
}
