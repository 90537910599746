/* stylelint-disable selector-no-vendor-prefix */

::-moz-selection {
  background-color: rgba(255 181 0 / 70%);
  color: theme('colors.gray.8');
}

::selection {
  background-color: rgba(255 181 0 / 70%);
  color: theme('colors.gray.8');
}

:focus-visible {
  outline-color: theme('colors.turmeric');
}

.bg-turmeric ::-moz-selection {
  background-color: rgba(33 33 33 / 70%);
  color: theme('colors.turmeric');
}

.bg-turmeric ::selection {
  background-color: rgba(33 33 33 / 70%);
  color: theme('colors.turmeric');
}
