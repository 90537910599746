.component {
  background-color: theme('colors.turmeric');
  bottom: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 200ms ease-out;
  z-index: 10;
}

.component hr {
  border-color: theme('colors.black');
}

.component button:not([type='button']) {
  background-color: transparent;
  border: unset;
  color: theme('colors.gray.8');
  font-size: theme('fontSize.32');
  outline: unset;
  text-decoration: unset;
  text-transform: uppercase;
}

.component.open {
  opacity: 1;
  pointer-events: all;
}

.close {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  left: 0;
  margin: 0;
  outline: unset;
  padding: theme('spacing.2x') theme('spacing.2xs');
  position: absolute;
  top: 0;
  width: 60px;
}

.close img {
  height: theme('spacing.4xs');
  width: theme('spacing.4xs');
}

.link {
  background-color: transparent;
  border: none;
  color: theme('colors.black');
  font-family: theme('fontFamily.central-no1-light');
  font-size: 28px;
  padding: 0;
  text-align: left;
  text-decoration: none;
}

/*
.link.active {
  border-left: 3px solid theme('colors.black');
  color: theme('colors.gray.9');
  padding-left: theme('spacing.2xs');
  pointer-events: none;
}
*/

.uppercase {
  font-family: theme('fontFamily.beatrice-medium');
  font-size: theme('fontSize.28');
  text-transform: uppercase;
}

@screen md {
  .component {
    visibility: hidden;
  }
}
