.component {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid theme('colors.pine');
  border-top: 1px solid theme('colors.pine');
  color: theme('colors.forest');
  cursor: pointer;
  display: inline-block;
  font-family: theme('fontFamily.beatrice-regular');
  font-size: theme('fontSize.16');
  outline: none;
  padding: theme('spacing.2xs') theme('spacing.3x');
  position: relative;
  text-align: center;
  text-decoration: unset;
  transition: border 200ms ease-out, padding 200ms ease-out;
  white-space: nowrap;
}

.component:after,
.component:before {
  background-color: theme('colors.turmeric');
  bottom: 15%;
  content: '';
  position: absolute;
  top: 15%;

  /* prettier-ignore */
  transition: background 140ms ease-in-out, bottom 140ms ease-in-out, top 140ms ease-in-out;
  width: 1px;
}

.component:after {
  right: 0;
}

.component:before {
  left: 0;
}

/*
a.component:hover,
button.component:hover {
  padding: theme('spacing.2xs') theme('spacing.8x');
}

.component:hover:after,
.component:hover:before {
  bottom: -3%;
  top: -3%;
}

.component.no-hover:hover {
  padding-left: unset;
  padding-right: unset;
}
*/

/**
 * THICK ~ 2px border and heavier font
 */
.component.thick {
  border-bottom-width: 2px;
  border-top-width: 2px;
  font-family: theme('fontFamily.beatrice-semibold');
}

.component.thick:after,
.component.thick:before {
  width: 2px;
}

/**
 * THEMES (forest | pine | rust | seaSalt | stormy | turmeric)
 */

/* FOREST */
.component.border-y-forest {
  border-color: theme('colors.forest');
}

.component.border-x-forest:after,
.component.border-x-forest:before {
  background-color: theme('colors.forest');
}

/* PINE */
.component.border-y-pine {
  border-color: theme('colors.pine');
}

.component.border-x-pine:after,
.component.border-x-pine:before {
  background-color: theme('colors.pine');
}

/* RUST */
.component.border-y-rust {
  border-color: theme('colors.rust');
}

.component.border-x-rust:after,
.component.border-x-rust:before {
  background-color: theme('colors.rust');
}

/* SEA SALT */
.component.border-y-seaSalt {
  border-color: theme('colors.seaSalt');
}

.component.border-x-seaSalt:after,
.component.border-x-seaSalt:before {
  background-color: theme('colors.seaSalt');
}

/* STORMY */
.component.border-y-stormy {
  border-color: theme('colors.stormy');
}

.component.border-x-stormy:after,
.component.border-x-stormy:before {
  background-color: theme('colors.stormy');
}

/* TURMERIC */
.component.border-y-turmeric {
  border-color: theme('colors.turmeric');
}

.component.border-x-turmeric:after,
.component.border-x-turmeric:before {
  background-color: theme('colors.turmeric');
}

/* WHITE */
.component.border-y-white {
  border-color: theme('colors.white');
}

.component.border-x-white:after,
.component.border-x-white:before {
  background-color: theme('colors.white');
}

/**
 * Disabled state: Must come after all other states
 */
.component:disabled {
  cursor: not-allowed;
}

.component:global(.disabled) {
  border-bottom-color: theme('colors.gray.2');
  border-top-color: theme('colors.gray.2');
  color: theme('colors.gray.5');
  cursor: not-allowed;
  opacity: 0.8;
}

.component:global(.disabled):after,
.component:global(.disabled):before {
  background-color: theme('colors.gray.2');
}
